<template>
  <div class="home" :class="$i18n.locale == 'en' ? 'en-home-wrap' : ''">
    <!-- header -->
    <headNav type="home"></headNav>
    <!-- 轮播图 -->
    <div class="home-top">
      <div class="home-carousel">
        <a-icon type="left" class="carousel-prev-btn" @click="handlePrev" />
        <a-carousel :dots="false" :autoplay="true" ref="carousel">
          <div
            v-for="(item, key) in focuslist"
            :key="key"
            class="carousel-item"
            @click="gotoPage(item)"
            :style="{
              backgroundImage: 'url(' + `${state.baseUrl}${item.thumb}` + ')',
            }"
          ></div>
        </a-carousel>
        <a-icon type="right" class="carousel-next-btn" @click="handleNext" />
      </div>
      <!-- 日历 -->
      <div class="home-calendar">
        <div class="date-content" v-if="dateList.length">
          <div class="left">
            <div class="mon">{{ dateList[0].month }}</div>
            <div class="year">{{ dateList[0].year }}</div>
          </div>
          <div class="right">
            <!-- 上一页 -->
            <div
              class="prev el-icon-arrow-left"
              @click="getDateList('prev')"
            ></div>
            <!-- 日期 -->
            <div class="data-list">
              <div v-for="(item, index) in dateList" :key="index" class="item">
                <div class="top" @click="selectShowDate(item, index)">
                  <div class="week">{{ item.week }}</div>
                  <div :class="['date', item.showItem ? 'date-active' : '']">
                    {{ item.day }}
                  </div>
                </div>
                <!-- 演出 -->
                <div
                  class="bot"
                  @click="gotoShowDetail(item.showItem)"
                  v-if="item.showItem && item.showFlag"
                >
                  <div class="text-1">
                    {{ templateTime(item.showItem.showtime2) }}
                  </div>
                  <div class="text-2 ellipsis">
                    {{ item.showItem.title }}
                  </div>
                  <div class="text-3">{{ item.showItem.venue }}</div>
                </div>
              </div>
            </div>
            <!-- 下一页 -->
            <div
              class="next el-icon-arrow-right"
              @click="getDateList('next')"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 音乐季 -->
    <div class="music-season pointer">
      <a href="https://www.suzhousco.com/#/music" target="_blank">
      <!-- <a href="https://suco.oss-cn-hangzhou.aliyuncs.com/yueji/pc_2024.pdf" target="_blank"> -->
        <img
          src="@/assets/version2/home-2.png"
          alt=""
          v-if="$i18n.locale == 'zh'"
        />
        <img src="@/assets/version2/homeEn-2.png" alt="" srcset="" v-else />
      </a>
    </div>
    <!-- 分类 -->
    <div class="bot-content">
      <!-- 国际巡演 -->
      <div class="international-tour">
        <div class="home-common-title">
          <div style="width: 43%"></div>
          <div class="title-wrap" style="width: 14%">
            <div class="text">{{ $t("home.internationalTour") }}</div>
          </div>
          <div style="width: 43%"></div>
          <img
            src="@/assets/version2/titleEn-1.png"
            alt=""
            class="titleEn-img"
          />
        </div>
        <div class="title-2">{{ $t("home.internationalTourSub") }}</div>
        <div class="list-wrap">
          <div class="list-item" @click="goToLocalPage('usshow')">
            <img src="@/assets/version2/home-5.png" alt="" class="img" />
            <div class="info info-1">
              <div class="text-1">{{ $t("home.ine1") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int1") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="goToLocalPage('othershow', 0)">
            <img src="@/assets/version2/home-6.png" alt="" class="img" />
            <div class="info info-2">
              <div class="text-1">{{ $t("home.ine2") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int2") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="goToLocalPage('othershow', 2)">
            <img src="@/assets/version2/home-7.png" alt="" class="img" />
            <div class="info info-3">
              <div class="text-1">{{ $t("home.ine3") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int3") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="goToLocalPage('othershow', 1)">
            <img src="@/assets/version2/home-8.png" alt="" class="img" />
            <div class="info info-4">
              <div class="text-1">{{ $t("home.ine4") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int4") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="goToLocalPage('europe')">
            <img src="@/assets/version2/home-9.png" alt="" class="img" />
            <div class="info info-5">
              <div class="text-1">{{ $t("home.ine5") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int5") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
          <div class="list-item" @click="goToLocalPage('japanTour')">
            <img src="@/assets/version2/home-33.png" alt="" class="img" />
            <div class="info info-6">
              <div class="text-1">{{ $t("home.ine6") }}</div>
              <div class="text-detail">
                <div class="text-2">{{ $t("home.int6") }}</div>
                <div class="text-2 text-3">{{ $t("home.details") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 数字音乐厅 -->
      <div class="Digital-Music-Hall">
        <div class="home-common-title">
          <div class="line" style="width: 41%"></div>
          <div class="title-wrap" style="width: 18%">
            <div class="text">{{ $t("home.digitalConcertHall") }}</div>
          </div>
          <div class="line" style="width: 41%"></div>
          <img
            class="titleEn-img"
            src="@/assets/version2/titleEn-2.png"
            alt=""
            style="width: 283px; height: 44px"
          />
        </div>
        <div class="content">
          <div class="left">
            <img src="@/assets/version2/home-10.png" alt="" class="img" />
          </div>
          <div class="right">
            <div class="top">
              <a href="https://dch.suzhousco.com/#/home" target="_blank">
                <div class="btn">{{ $t("home.wait") }}</div>
              </a>
            </div>
            <div class="bot">
              <div class="text">
                <div>{{ $t("home.suzhou") }}</div>
                <div>{{ $t("home.digitalConcertHall") }}</div>
              </div>
              <img src="@/assets/version2/home-11.png" alt="" class="img" />
            </div>
          </div>
        </div>
      </div>
      <!-- 公益惠民 系列演出 -->
      <div class="public-benefit">
        <div class="home-common-title">
          <div
            class="line"
            :class="$i18n.locale == 'en' ? 'line-en' : 'line-ch'"
          ></div>
          <div
            class="title-wrap"
            :class="$i18n.locale == 'en' ? 'title-wrap-en' : 'title-wrap-ch'"
          >
            <div class="text">{{ $t("home.publicBenefit") }}</div>
          </div>
          <div
            class="line"
            :class="$i18n.locale == 'en' ? 'line-en' : 'line-ch'"
          ></div>
          <img
            class="titleEn-img"
            src="@/assets/version2/titleEn-3.png"
            alt=""
          />
        </div>
        <div class="content">
          <div class="item pointer" @click="goToLocalPage('publicBenefit', 0)">
            <div class="img img-1"></div>
            <div class="bot bot-1">
              <div class="text">
                {{ $t("publicBenefit.concert.text1") }}
              </div>
            </div>
          </div>
          <div class="item pointer" @click="goToLocalPage('publicBenefit', 1)">
            <div class="bot bot-2">
              <div class="text">
                <div>{{ $t("publicBenefit.forum.text1") }}</div>
              </div>
            </div>
            <div class="img img-2"></div>
          </div>
          <div class="item pointer" @click="goToLocalPage('publicBenefit', 2)">
            <div class="img img-3"></div>
            <div class="bot bot-3">
              <div class="text">
                {{ $t("publicBenefit.school.text1") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 品牌活动 -->
      <div class="brand-activity">
        <div class="home-common-title">
          <div class="line" style="width: 43%"></div>
          <div class="title-wrap" style="width: 14%">
            <div class="text">{{ $t("home.brandActivity") }}</div>
          </div>
          <div class="line" style="width: 43%"></div>
          <img
            class="titleEn-img"
            src="@/assets/version2/titleEn-4.png"
            alt=""
            style="width: 225px; height: 34px"
          />
        </div>
        <div class="content">
          <div>
            <!-- 74届 -->
            <div
              class="item item-1"
              @click="goToLocalPage('brandActivity', brandActivityList[0].id)"
              v-if="brandActivityList.length > 0"
            >
              <div class="left">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="text">
                  <div>{{ brandActivityList[0].title }}</div>
                </div>
              </div>
              <div
                class="right"
                :style="{
                  backgroundImage:
                    'url(' + state.baseUrl + brandActivityList[0].thumb + ')',
                }"
              ></div>
            </div>
            <!-- 音乐展演 -->
            <div
              class="item item-2"
              @click="goToLocalPage('brandActivity', brandActivityList[1].id)"
              v-if="brandActivityList.length > 1"
            >
              <div
                class="right"
                :style="{
                  backgroundImage:
                    'url(' + state.baseUrl + brandActivityList[1].thumb + ')',
                }"
              ></div>
              <div class="left">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="text">
                  <div>{{ brandActivityList[1].title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="home-news-wrap" v-if="newsList.length">
      <div class="home-common-title">
        <div class="line" style="width: 43%"></div>
        <div class="title-wrap" style="width: 14%">
          <div class="text">{{ $t("home.news") }}</div>
        </div>
        <div class="line" style="width: 43%"></div>
        <img
          class="titleEn-img"
          src="@/assets/version2/titleEn-5.png"
          alt=""
          style="width: 86px; height: 34px"
        />
      </div>
      <div class="home-news">
        <div class="w1200 flex wrap">
          <div
            class="item pointer"
            v-for="(item, key) in newsList"
            :key="key"
            @click="readMore(item)"
          >
            <div class="text-1 ellipsis2">
              {{ item.title }}
            </div>
            <div class="text-2 mt32">{{ templateTime(item.inputtime) }}</div>
            <div class="text-3 mt12 ellipsis3">
              {{ item.description }}
            </div>
            <div class="mt40 text-4">{{ $t("home.viewMore") }}>></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="home-video page-width">
      <div class="w1200" style="height: 619px">
        <video
          id="my-video"
          class="video video-js vjs-default-skin"
          controls
          poster="../assets/version2/home-20.png"
          src=""
        >
        <source src="https://c.vod.suzhousco.com/90d2129da58371efbc5b6633b79f0102/f4a17e1e3ae047ff92330b613aecfe5d.m3u8" type="application/x-mpegURL">
          your browser does not support the video tag
        </video>
      </div>
    </div>
    <footNav />
    <!-- 回到顶部 -->
    <a-back-top />
  </div>
</template>

<script>
// @ is an alias to /src
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import moment from "moment";
import videojs from "video.js";
import "videojs-contrib-hls";
const artist = [
  {
    id: "1",
    name: "彭家鹏",
    py: "Pang Kapang",
    level1: "艺术总监兼首席指挥<br />国家一级指挥",
    level2:
      "Artistic Director and Chief Conductor<br />National 1st Class Conductor",
  },
  {
    id: "2",
    name: "朱昌耀",
    py: "Zhu Changyao ",
    level1: "艺术指导<br />国家一级演奏员",
    level2: "Artistic Instructor<br />renowned Erhu performer and composer",
  },
];
const counselor = [
  {
    id: "3",
    name: "赵季平",
    py: "ZHAO JIPING",
    level1:
      "中国音乐家协会名誉主席<br />陕西省文学艺术界联合会主席<br />中国文学艺术界联合会第十届荣誉委员",
    level2:
      "Honorary Chairman of Chinese Musicians<br />Chairman of Shaanxi Federation of Literary and Art Circles<br />10th Honorary Member of Chinese Federation of Literary and Art Circles",
  },
  {
    id: "4",
    name: "刘锡津",
    py: "LIU XIJIN",
    level1:
      "现任中国民族管弦乐学会会长<br />中国歌剧研究会副主席<br />国家级优秀专家",
    level2:
      "President of China Nationalities Orchestra Society<br />Vice President of China Opera Research Society<br />National Outstanding Expert",
  },
  {
    id: "5",
    name: "王书伟",
    py: "WANG SHUWEI",
    level1:
      "中国广播艺术团原团长、党委书记<br />中国民族管弦乐学会第五届副会长<br />中国民族管弦乐学会第六届副会长秘书长",
    level2:
      "Former President and Secretary of the Party Committee of China Broadcasting and Art Troupe<br />Nationalities Orchestra Society",
  },
  {
    id: "6",
    name: "顾冠仁",
    py: "GU GUANREN",
    level1:
      "国家一级作曲<br />历任上海民族乐团团长、艺术总<br />中国民族管弦乐学会副会长",
    level2:
      "National First-grade Composer<br />President and Artistic Director of Shanghai Chinese Orchestra<br />Vice President of China Nationalities Orchestra Society",
  },
];
export default {
  name: "Home",
  components: {
    headNav,
    footNav,
  },
  data() {
    return {
      focuslist: [],
      hotList: [],
      showlist: [],
      newsList: [],
      initDate: [],
      timeObj: null,
      activeIndex: 0,
      activeList: [],
      activeShow: null,
      dateList: [],
      showDayList: [],
      brandActivityList: [], // 品牌活动列表
    };
  },
  mounted() {
    this.getVideo();
},
  created() {
    this.getInitData();
    // 获取演出列表
    this.getShowList();
    this.getNewsList();
    let activeList = [];
    activeList.push(artist);
    this.activeList = activeList;
    // 获取品牌活动
    this.getBrandActivityData();
  },
  computed: {
    state() {
      return this.$store.state;
    },
    nowString() {
      if (this.timeObj) {
        return moment(this.timeObj).format("YYYY.M");
      } else {
        return moment().format("YYYY.M");
      }
    },
  },
  methods: {
    getVideo() {
      videojs(
          "my-video",
          {
              bigPlayButton: false,
              textTrackDisplay: false,
              posterImage: true,
              errorDisplay: false,
              controlBar: true
          },
          function() {
              this.play();
          }
      );
    },
    // 获取品牌活动
    getBrandActivityData() {
      this.$ajax.get("/cms/api/pinpailist", {}).then((res) => {
        if (res.code == "200") {
          let data = res.data || [];
          this.brandActivityList = data.slice(0, 2);
        }
      });
    },
    // 页面跳转
    goToLocalPage(name, type) {
      if (type || type == 0) {
        this.$router.push({ name: name, query: { type: type } });
      } else {
        this.$router.push({ name: name });
      }
    },
    // 选择演出时间
    selectShowDate(curItem, curIndex) {
      this.dateList.forEach((item) => {
        item.showFlag = false;
      });
      this.showlist.forEach((item) => {
        let time = moment(item.showtime2 * 1000).format("YYYY-MM-DD");
        if (time == curItem.formatDate) {
          this.dateList[curIndex].showItem = item;
          this.dateList[curIndex]["showFlag"] = true;
        }
      });
      this.$forceUpdate();
    },
    // 初始化获取时间列表
    initGetDateList() {
      if (this.$i18n.locale == "en") {
        this.showDayList = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      } else {
        this.showDayList = [
          "周日",
          "周一",
          "周二",
          "周三",
          "周四",
          "周五",
          "周六",
        ];
      }
      let dateList = [];
      for (let i = 0; i < 15; i++) {
        // 获取时间date格式
        let date = new Date();
        date.setDate(date.getDate() + i);
        // yyyy-mm-dd格式
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (day >= 0 && day <= 9) {
          day = "0" + day;
        }
        var formatDate = year + "-" + month + "-" + day;
        // 获取周几
        var week = this.showDayList[date.getDay()];
        let dateObj = {
          formatDate: formatDate,
          day: day,
          week: week,
          year: year,
          month: month,
        };
        dateList.push(dateObj);
      }
      // 筛选出存在演出的日期
      this.dateList = this.filterShow(dateList);
    },
    // 筛选出存在演出的日期
    filterShow(data) {
      let dateList = data;
      let firstShowFlag = true;
      for (let i = 0; i < dateList.length; i++) {
        for (let j = 0; j < this.showlist.length; j++) {
          let time = moment(this.showlist[j].showtime2 * 1000).format(
            "YYYY-MM-DD"
          );
          if (dateList[i].formatDate == time) {
            dateList[i]["showItem"] = this.showlist[j];
            if (firstShowFlag) {
              dateList[i]["showFlag"] = false;
              firstShowFlag = false;
            }
          }
        }
      }
      return dateList;
    },
    // 获取时间列表翻页
    getDateList(type) {
      let dateList = [];
      for (let i = 1; i < 16; i++) {
        let date = {};
        // 获取时间date格式
        if (type == "prev") {
          // 获取当前第一个日期
          date = new Date(this.dateList[0].formatDate);
        } else {
          // 获取最后一个日期
          date = new Date(this.dateList[this.dateList.length - 1].formatDate);
        }
        // 翻页
        if (type == "prev") {
          date.setDate(date.getDate() - i);
        } else {
          date.setDate(date.getDate() + i);
        }
        // yyyy-mm-dd格式
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (day >= 0 && day <= 9) {
          day = "0" + day;
        }
        var formatDate = year + "-" + month + "-" + day;
        // 获取周几
        var week = this.showDayList[date.getDay()];
        let dateObj = {
          formatDate: formatDate,
          day: day,
          week: week,
          year: year,
          month: month,
        };
        dateList.push(dateObj);
      }
      if (type == "prev") {
        // 向前查看时 需要颠倒日期顺序
        dateList = dateList.reverse();
      }
      // 筛选出存在演出的日期
      this.dateList = this.filterShow(dateList);
    },
    getInitData() {
      // 获取焦点图
      this.$ajax.get("/cms/api/focuslist", {}).then((res) => {
        if (res.code == "200") {
          this.focuslist = res.data;
        }
      });
    },

    // 获取演出列表
    getShowList() {
      this.$ajax.get("/cms/api/showlist").then((res) => {
        if (res.code == "200") {
          this.showlist = res.data;
          // 初始化时间控件
          this.initGetDateList();
        }
      });
    },

    getShowListByTime() {
      this.$ajax.get("/cms/api/showlist").then((res) => {
        if (res.code == "200") {
          this.showlist = res.data;
          if (res.data.length) {
            let initDate = [];
            res.data.forEach((item) => {
              let time = moment(item.showtime2 * 1000).format("YYYY-MM-DD");
              initDate.push(time);
            });
            this.initDate = this.unique(initDate);
            this.selectEvent(this.initDate[0]);
          } else {
            this.initDate = [];
          }
          // this.hotList = res.data.slice(0,5)
        }
      });
    },

    // 跳转详情
    gotoDetail(id) {
      if (this.activeIndex == 0) {
        this.$router.push({ name: `artist-${id}`, params: { id: id } });
      } else {
        this.$router.push({ name: "artistic" });
      }
    },

    // 跳转详情
    gotoShowDetail(item) {
      if (item) {
        this.$router.push({ name: `show-detail`, params: { id: item.id } });
      }
    },

    unique(arr) {
      return Array.from(new Set(arr));
    },

    // 获取新闻列表
    getNewsList() {
      this.$ajax.get("/cms/api/newslist", {}).then((res) => {
        if (res.code == "200") {
          this.newsList = res.data.slice(0, 6);
        }
      });
    },
    // 修改时间
    changeTime(type) {
      let now = this.timeObj ? this.timeObj : moment();
      let date;
      if (type == "add") {
        date = moment(now).add(1, "month").format("YYYY-MM");
      } else {
        date = moment(now).subtract(1, "month").format("YYYY-MM");
      }
      this.timeObj = date;
      this.initDate = [];
      this.activeShow = null;
      this.getShowListByTime();
    },

    templateTime(time) {
      if (!time) return;
      return moment(time * 1000).format("YYYY-MM-DD");
    },

    gotoNext(type) {
      if (type == "prev") {
        this.$refs["HMusic"].prev();
      } else {
        this.$refs["HMusic"].next();
      }
    },

    headerRender() {
      return "";
    },

    selectEvent(date) {
      let selectDate = moment(date).format("YYYY-MM-DD");
      this.timeObj = selectDate;
      this.showlist.forEach((item) => {
        let time = moment(item.showtime2 * 1000).format("YYYY-MM-DD");
        if (time == selectDate) {
          this.activeShow = item;
        }
      });
    },

    changeActive(index) {
      this.activeIndex = index;
      let activeList = [];
      if (index) {
        activeList.push(counselor);
        activeList = [
          [counselor[0], counselor[1]],
          [counselor[2], counselor[3]],
        ];
      } else {
        activeList.push(artist);
      }
      this.activeList = activeList;
    },

    readMore(item) {
      this.$router.push({ name: "news-detail", params: { id: item.id } });
    },
    initDateFn(time) {
      let cur = moment(time).format("YYYY-MM-DD");
      return this.initDate.indexOf(cur) == -1;
    },

    gotoPage(item) {
      if (!item.pageurl) return;
      window.open(item.pageurl, "_blank");
    },
    handlePrev() {
      this.$refs.carousel.prev();
    },
    handleNext() {
      this.$refs.carousel.next();
    },
  },
};
</script>
<style lang="scss" scoped>
video::-webkit-media-controls-enclosure {
  display: none;
}
</style>
<style lang="scss" scoped>
.home {
  background-color: #f7f7f7;
}
.home-common-title {
  display: flex;
  position: relative;
  .line {
    border-bottom: 1.5px solid rgb(190, 190, 190);
  }
  .title-wrap {
    text-align: center;
    margin-top: -25px;
    .text {
      font-size: 35px;
      font-weight: 300;
      color: #4d4d4d;
      white-space: nowrap;
      letter-spacing: 2px;
    }
  }
  .line-ch {
    width: 39%;
  }
  .title-wrap-ch {
    width: 22%;
  }
  .line-en {
    width: 33%;
  }
  .title-wrap-en {
    width: 34%;
  }
  .titleEn-img {
    position: absolute;
    top: 6px;
    z-index: 99;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@keyframes anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.home-top {
  position: relative;
  height: 1100px;
}
// 首页轮播图
.home-carousel {
  overflow: hidden;
  position: relative;
  .carousel-prev-btn,
  .carousel-next-btn {
    display: none;
    font-size: 80px;
    color: #f2f6f7;
    position: absolute;
    top: 40%;
    z-index: 10;
    width: 80px;
    height: 80px;
    animation: anim 0.4s;
  }
  .carousel-prev-btn {
    left: 0;
  }
  .carousel-next-btn {
    right: -0;
  }
  &:hover {
    .carousel-prev-btn,
    .carousel-next-btn {
      display: block;
    }
  }
  .ant-carousel .slick-slide {
    text-align: center;
    background: #131419;
    overflow: hidden;
    height: auto;
  }
  .carousel-item {
    height: 887px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
// 日历
.home-calendar {
  width: 100%;
  height: 440px;
  background-image: url("../assets/version2/home-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -70px;
  z-index: 0;
  .date-content {
    width: 1200px;
    height: 211px;
    margin: 0 auto;
    position: relative;
    top: 55%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    text-align: center;
    .left {
      width: 60px;
      font-size: 26px;
      font-family: Roboto, Roboto-Light;
      font-weight: 300;
      line-height: 30px;
      .mon {
        color: #634636;
        border-bottom: 1px solid #666666;
      }
      .year {
        color: #666666;
      }
    }
    .right {
      width: 1140px;
      display: flex;
      justify-content: space-between;
      .prev,
      .next {
        width: 17px;
        height: 24px;
        color: #333333;
        line-height: 60px;
        font-size: 28px;
        cursor: pointer;
        &:hover {
          color: #634636;
        }
      }
      .prev {
        margin: 0 20px 0 15px;
      }
      .next {
        margin: 0 15px 0 20px;
      }
      .data-list {
        width: 1100px;
        display: flex;
        justify-content: space-between;
        .item {
          position: relative;
          width: 35px;
          font-weight: 300;
          cursor: pointer;
          .week {
            font-size: 16px;
            color: #666666;
          }
          .date {
            font-size: 24px;
            font-family: Roboto, Roboto-Light;
            color: #333333;
            &-active {
              width: 34px;
              height: 34px;
              line-height: 32px;
              background: #8c6239;
              text-align: center;
              border-radius: 50%;
              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              box-shadow: 0px 4px 4px rgba(140, 98, 57, 0.2);
            }
          }
        }
        .bot {
          width: 335px;
          position: absolute;
          top: 74px;
          left: -147px;
          z-index: 98;
          text-align: left;
          padding: 10px 24px;
          background: #8c6239;
          border-radius: 5px;
          .text-1 {
            font-size: 16px;
            font-family: Roboto, Roboto-Light;
            font-weight: 300;
            color: #ffffff;
          }
          .text-2 {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
          }
          .text-3 {
            opacity: 0.8;
            font-size: 12px;
            font-weight: 300;
            color: #ffffff;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: -7px;
            left: 50%;
            z-index: 99;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #8c6239;
            margin-left: -12px;
          }
        }
      }
    }
  }
}
// 音乐季
.music-season {
  position: relative;
  z-index: 0;
  img {
    width: 100%;
    height: auto;
  }
}
.bot-content {
  background-image: url("../assets/version2/home-4.png");
  background-color: rgba(255, 255, 255, 1);
  background-size: 100% 100%;
}
// 国际巡演
.international-tour {
  padding-top: 40px;
  padding-bottom: 106px;
  .title-2 {
    font-size: 22px;
    font-weight: 300;
    color: #927e73;
    letter-spacing: 25px;
    margin-top: 26px;
  }
  .list-wrap {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 325px;
    margin: 70px auto 0;
    overflow-x: auto;
    flex-wrap: nowrap;
    .list-item {
      width: 230px;
      flex-shrink: 0;
      margin-right: 14px;
      cursor: pointer;
      .img {
        width: 100%;
        height: 135px;
      }
      .info {
        height: 173px;
        padding: 17px 0 13px;
        .text-1 {
          font-size: 18px;
          color: #ffffff;
          text-align: center;
        }
        .text-detail {
          padding: 0 23px 0;
        }
        .text-2 {
          font-size: 12px;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.9;
          margin-top: 8px;
          text-align: left;
        }
        .text-3 {
          width: 72px;
          line-height: 24px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          margin-top: 16px;
        }
      }
      .info-1 {
        background: #634636;
      }
      .info-2 {
        background: #292929;
      }
      .info-3 {
        background: #ad302b;
      }
      .info-4 {
        background: #6666cc;
      }
      .info-5 {
        background: #e08a00;
        .text-1 {
          padding: 0 23px 0;
        }
      }
      .info-6 {
        background: #169BBA;
        .text-1 {
          padding: 0 23px 0;
        }
      }
    }
    .list-item-5 {
      .info {
        .text-3 {
          margin-top: 0;
        }
        .text-4 {
          margin: 27px 0;
          text-align: center;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}
// 数字音乐厅
.Digital-Music-Hall {
  padding-bottom: 106px;
  .content {
    width: 1200px;
    margin: 70px auto 0;
    display: flex;
    height: 635px;
    border-radius: 128px 0 128px 0;
    overflow: hidden;
    .left .img {
      width: 930px;
      height: 635px;
    }
    .right {
      width: 270px;
      .top {
        background: #292929;
        height: 232px;
        padding-top: 120px;
        .btn {
          width: 134px;
          line-height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .bot {
        height: 403px;
        background: #ad302b;
        text-align: center;
        position: relative;
        padding-top: 126px;
        .text {
          line-height: 40px;
          font-size: 22px;
          font-weight: 300;
          color: #ffffff;
          letter-spacing: 5px;
          padding: 0 20px;
        }
        .img {
          position: absolute;
          bottom: 0;
          left: -305px;
          z-index: 99;
        }
      }
    }
  }
}
// 公益惠民
.public-benefit {
  padding-bottom: 106px;
  .content {
    width: 900px;
    margin: 70px auto 0;
    display: flex;
    justify-content: space-between;
    .item {
      width: 243px;
      border-radius: 0 93px 0 93px;
      overflow: hidden;
      .img,
      .bot {
        width: 100%;
        height: 275px;
        background-size: 100% 100%;
      }
      .img-1 {
        background-image: url("../assets/version2/home-12.png");
      }
      .img-2 {
        margin-top: -1px;
        background-image: url("../assets/version2/home-13.png");
      }
      .img-3 {
        background-image: url("../assets/version2/home-14.png");
      }
      .bot {
        text-align: center;
        line-height: 275px;
        .text {
          color: #ffffff;
          font-weight: 300;
          font-size: 26px;
          display: inline-block;
          vertical-align: middle;
          line-height: 30px;
        }
      }
      .bot-1 {
        background: #634636;
      }
      .bot-2 {
        background: #1a1a1a;
      }
      .bot-3 {
        background: #634636;
      }
    }
  }
}
// 品牌活动
.brand-activity {
  padding-bottom: 50px;
  .content {
    width: 1200px;
    margin: 70px auto 0;
    .item {
      display: flex;
      justify-content: space-between;
      height: 490px;
      border-radius: 93px 0 93px 0;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 57px;
      .left {
        width: 315px;
        height: 490px;
        position: relative;
        text-align: center;
        line-height: 490px;
        padding: 0px 36px;
        .text {
          font-size: 30px;
          font-weight: 300;
          color: #ffffff;
          display: inline-block;
          vertical-align: middle;
          line-height: 40px;
        }
        .dot {
          position: absolute;
          z-index: 99;
          width: 59px;
          height: 201px;
          background-size: 100% 100%;
        }
        .dot-1 {
          background-image: url("../assets/version2/home-18.png");
          right: 0;
          top: 20px;
        }
        .dot-2 {
          background-image: url("../assets/version2/home-16.png");
          left: 0;
          bottom: 20px;
        }
      }
      .right {
        width: 885px;
        height: 490px;
        background-size: 100% 100%;
      }
    }
    .item-1 {
      .left {
        background: #ad302b;
      }
    }
    .item-2 {
      .left {
        background: #e08a00;
      }
    }
  }
}
// 新闻
.home-news-wrap {
  padding: 7px 0 88px;
  background: url("../assets/version2/home-19.png");
  background-size: 100% 100%;
  .item {
    width: 387px;
    height: 359px;
    opacity: 0.74;
    background: #ffffff;
    text-align: left;
    padding: 52px 50px 56px;
    box-sizing: border-box;
    position: relative;
    margin: 40px 19px 0 0;
    font-weight: 300;
    ::before {
      content: "";
      display: block;
      width: 7px;
      height: 48px;
      position: absolute;
      left: 0;
      top: 52px;
      opacity: 0.74;
      background: #b59d8f;
    }
    .text-1 {
      font-size: 26px;
      color: #656565;
      line-height: 37px;
    }
    .text-2 {
      font-size: 16px;
      color: #8d8d8d;
    }
    .text-3 {
      font-size: 14px;
      color: #8d8d8d;
    }
    .text-4 {
      font-size: 18px;
      color: #656565;
      line-height: 18px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
// 视频
.home-video {
  padding: 90px 0 110px;
  .video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.en-home-wrap {
  .international-tour {
    .title-2 {
      letter-spacing: 2px;
    }
    .list-wrap {
      .list-item-5 {
        .info {
          .text-4 {
            margin: 14px 0;
          }
        }
      }
    }
  }
}
</style>
